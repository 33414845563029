<template>
  <b-container>
    <script 
      type="application/javascript"
      defer
      src="https://sandbox.web.squarecdn.com/v1/square.js"
    />

    <h1>Square Test</h1>
    <form id="payment-form">
      <div id="card-container" />
      <button
        id="card-button" 
        type="button"
        @click="processCard" 
      >
        Pay $1.99
      </button>
    </form>
    <div id="payment-status-container" />
  </b-container>
</template>


<!--
https://developer.squareup.com/forums/t/need-help-trying-to-implement-square-web-sdk-on-a-vue-js-app/3080
-->

<script>

export default  {

  data() {
    return {
      SQUARE_ACCESS_TOKEN : "EAAAEC06jvNBFNWPrMU3zLF_8g503vQ9elk_tl8LRGmSeiKlEHAey9xJihiVcj5M",
      APPLICATION_ID  : "sandbox-sq0idb-3kjHcu4bOldfaqe5UKOyHQ",
      LOCATION_ID  : "NTKN3CN5YEB3S"
    }
  },


 async mounted() {
    const payments = window.Square.payments(this.APPLICATION_ID, this.LOCATION_ID)

    this.card = payments.card()

    this.card.attach('#card-container')

    console.log("Attachment complete")
  },

  methods: {


    processCard()  {

      try {
        const result = this.card.tokenize();
        if (result.status === 'OK') {
          console.log(`Payment token is ${result.token}`)
        }

      } catch (e) {
        console.error(e)
      }
    }

  },



}     
</script>




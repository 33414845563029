



























































































































































































































































































































































































































































































































































































import Repository from "../Repository";

import { ProfileType, SquareType } from '../types'

// import { Client, Environment } from 'square'

import { Vue, Component } from 'vue-property-decorator'



@Component
export default class Signup extends Vue {
  
   
  step0 : boolean = true  // products
  step2 : boolean = false // email
  step4 : boolean = false // contact info
  step6 : boolean = false // terms and conditions
  step8 : boolean = false // payment
  

  profile: ProfileType = new ProfileType()
  square: SquareType = new SquareType()

  email : string = ""
  email_confirm : string = ""

  pwd : string = ""
  pwd_fieldtype : string = "text"
  pwdconfirm : string = ""
  pwdconfirm_fieldtype : string = "text"


  products: Array<any> = []
  product_selected : string = ""

  states: Array<any> = []
  timezones: Array<any> = []

  tandc: string = ""
  tandc_accepted: boolean = false

  mounted() {
    Repository.get("products").then(
      res => (this.products = res.data, this.product_selected = this.products[0].code)
    )


    Repository.get("states").then(
      res => (this.states = res.data, this.profile.stateprovinceid = this.$store.getters.pref.new_customerstateprovinceid)
    )

      Repository.get("timezone").then(
        res => (this.timezones = res.data, this.profile.timezoneid = this.$store.getters.pref.timezone)
      )

    Repository.get(`page/tandc`)
      .then(res => (this.tandc = res.data)
    )
  }

  // Step 1
  choseProduct()  {
    console.log("Product chosen")
    this.step0 = false
    this.step2 = true
  }


  // Step 2
  createPlaceholder() {
    let email = this.email
    let email_confirm = this.email_confirm

    if (!this.emailstate || !this.email_confirmstate)  {
      console.log("No can do")
      return
    }

    console.log("Dispatching...")
    this.$store
      .dispatch("placeholder", { email, email_confirm })
      .then(() => {
        this.$bvToast.toast(
          this.$store.getters.message,
          this.$store.getters.messageOptions
        )

        if (this.$store.getters.messageOptions.variant == "success")  {
          this.step2 = false
          this.step4 = true
          this.step6 = false

          // placeholder login
        }

      })

  }

  // Step 4:
  showProfile() {
    this.step2 = false
    this.step4 = true
  }


  // Step 6:
  saveProfile() {
    console.log("Save Profile")

    this.profile.email = this.email

    const user = {
      email: this.email,
      emailconfirm: this.email_confirm,
      pwd: this.pwd,
      pwdconfirm: this.pwdconfirm,

      profile: this.profile
    }

    Repository.put("placeholder/profile", user)
      .then(res => {
        
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }
        

        this.$bvToast.toast(res.data.content, res.data.options);

        this.step4 = false
        this.step6 = true 
      })  
     
  }


  // Step 8
  showPayment() {
    // https://stackoverflow.com/questions/45047126/how-to-add-external-js-scripts-to-vuejs-components


    console.log("Loading Square...")

    Repository.get("square")
      .then(res => {
        
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.square.square_script = res.data.square_script
        this.square.square_source_id = res.data.square_source_id
        this.square.applicationid = res.data.applicationid
        

        this.step6 = false
        this.step8 = true 
      }) 

  }


  validateEmail(email:string | null) {

    if (email == null) return null

    var re = /\S+@\S+\.\S+/
    return re.test(String(email).toLowerCase())
  }


  // Computed
  get step0_header(): string[] {

    if (this.step0)  {
      return []
    }

    return ["bg-success","text-white"]
  }

  get step2_header(): string[] {

    if (this.step2 || this.step0)  {
      return []
    }

    return ["bg-success","text-white"]
  }

  get step4_header(): string[] {

    if (this.step4 ||this.step2 || this.step0)  {
      return []
    }

    return ["bg-success","text-white"]
  }

  get step6_header(): string[] {

    if (this.step6 ||this.step4 || this.step2 || this.step0)  {
      return []
    }

    return ["bg-success","text-white"]
  }


  get emailstate(): boolean | null {
    if (this.email !== null && this.email.length < 10) return null
    if (this.email !== null && !this.validateEmail(this.email)) return false

    return true
  }

  get email_confirmstate(): boolean | null  {
    if (this.email_confirm !== null && this.email_confirm.length < 10) return null
    if (this.email_confirm !== null && !this.validateEmail(this.email_confirm)) return false
    if (this.email !== this.email_confirm) return false 

    return true
  }

  get namestate(): boolean | null	{

    // only on initial load does it not have a validation state
    if (this.profile.name.length > 2)	{
      return true
    }
  
    return null;
  }

  get pwdstate(): boolean | null	{


    if (this.pwd.length == 0)	{
      return null;
      }

    if (this.pwd.length < 8)	{
      return false;
    }


    // password rules need to match service/account
    // https://stackoverflow.com/questions/11896599/javascript-code-to-check-special-characters
    var matches 	= this.pwd.match(/\d+/g);
    var matches2 	= this.pwd.match(/\W+/g);

    if (!matches || !matches2)	{
      return false;
      }

    return true;
  }


  get pwdconfirmstate(): boolean | null	{


    if (this.pwdconfirm.length == 0)	{
      return null;
    }

    if (this.pwd.length < 8)	{
      return false;
    }


    // does not match
    if (this.pwd != this.pwdconfirm)	{
      return false;
    }

    return true;
  }

  // Similar to contacts
  // This is different because it goes into a different control
  get smsstate(): string | null {
      return this.validatePhone(this.profile.sms)
  }

  get phonestate(): string | null {
      return this.validatePhone(this.profile.phone)
  }

  validatePhone(phone:string | null): string | null {

    if (phone == null || phone == "") return null

    if (phone.startsWith("(1") || phone.startsWith("1") || phone.startsWith("(0") || phone.startsWith("0"))  {
      return "is-invalid"
    }

    if (phone.replace(/\D/g,'').length >= 10) return "is-valid"

    return null
  }  

  get isLoggedIn(): boolean {
    return this.$store.getters.isLoggedIn
  }
}
